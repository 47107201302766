<template>
<div class="box">
     <div class="logo">
        <img src="@/assets/logo.png"/>
     </div>
     <div class="form-item">
        <div class="name">手机号</div>
        <div class="val">
          <input type="number" name="mobile" v-model="form.mobile" maxlength="11" placeholder="请输入手机号" oninput="value=value.replace(/[^\d]/g,'')"/>
        </div>
     </div>
     <div class="form-item">
        <div class="name">密码</div>
        <div class="val">
          <input type="password" name="password" v-model="form.password" maxlength="18" placeholder="请输入6至18位密码"/>
        </div>
     </div>
     <div class="form-item">
        <div class="name">确认密码</div>
        <div class="val">
          <input type="password" name="confirmPassword" v-model="form.confirmPassword" maxlength="18" placeholder="请再次输入6至18位密码"/>
        </div>
     </div>
     <div class="form-item">
        <div class="name">邀请码</div>
        <div class="val">
          <input type="text" name="inviteCode" :value="form.inviteCode" :disabled="form.inviteCode ? true : false" maxlength="6" placeholder="必填"/>
        </div>
     </div>
     <label class="radio-agreement">
        <input type="checkbox" v-model="form.age" name="age" :value="true" />
        <div class="agreement">
          <span class="href">我已知悉，并年满18岁</span>
        </div>
     </label>
     <label class="radio-agreement">
        <input type="checkbox" v-model="form.agreement" :value="true" name="agreement" />
        <div class="agreement">
          <span>我已阅读并同意</span>
          <span class="href" @click.stop="onYhxy">《用户协议》</span>
          <span>及</span>
          <span class="href" @click.stop="onyszc">《隐私协议》</span>
        </div>
     </label> 
     <button class="button-submit" @click="submit">确定注册</button>
     <div class="button-download" @click="download">我已注册过了，直接下载APP</div>
    <div class="copyright">
        <div>Copyright@2023</div>
        <div>高宝信息科技版权所有</div>
    </div>
    <div class="popup" v-if="isRegSuccess">
        <div class="content">
            <img class="icon-success" src="../../assets/icon-success.png"/>
            <div class="success">恭喜注册成功</div>
            <div class="tips">2秒后自动进入APP下载页</div>
            <div class="download" @click="download">立即下载</div>
        </div>
    </div>
</div>
</template>
<script>
import {Toast} from 'vant';
export default {
    data() {
        return {
            loading: false,
            isRegSuccess: false,
            form:{
                mobile: '',
                password: '',
                confirmPassword: '',
                age: false,
                agreement: false,
                inviteCode: ''
            }
        }
    },
    created(options){
     let inviteCode = this.$route.params.inviteCode || localStorage.getItem("invitecode");
     if(inviteCode && inviteCode != ""){
        this.form.inviteCode = inviteCode;
        localStorage.setItem("invitecode", inviteCode);
     }
     console.log(inviteCode)
    },
    methods:{
        onYhxy(){
            this.$router.push({
                path: '/agreement/yhxy'
            })
        },
        onyszc(){
            this.$router.push({
                path: '/agreement/yszc'
            })
        },
        submit(){
            let form = this.form;
            try{
                if(!form.mobile){
                throw '请输入手机号';
                }
                if(form.mobile.length != 11){
                throw '手机号格式不正确';
                }
                if(form.password.length < 6 || form.password.length > 18){
                throw '请输入6-18位密码';
                }
                if(form.confirmPassword.length < 6 || form.confirmPassword.length > 18){
                throw '请输入确认密码';
                }
                if(form.confirmPassword != form.password){
                throw '两次输入的密码不一致';
                }
                if(!form.inviteCode){
                throw '请输入邀请码';
                }
                if(form.inviteCode.length < 6){
                throw '请输入正确的邀请码';
                }
                if(form.age != true){
                throw '请勾选我已知悉，并年满18岁';
                }
                if(form.agreement != true){
                throw '请阅读并同意《用户协议》《隐私协议》';
                }
            }catch(err){
                Toast({ message: err,position:'cente'});
                return false;
            }
            if(this.loading) return;
            this.loading = true;
            Toast.loading({message: '加载中...',duration: 0});
            this.request.post("/users/reg", {
                mobile: form.mobile,
                password: form.password,
                confirmPassword: form.confirmPassword,
                inviteCode: form.inviteCode,
                inviteUid: ""
            }).then(res=>{
                if(res.code == 1){
                    //Toast.success('注册成功');
                    this.isRegSuccess = true;
                    let that = this;
                    setTimeout(()=>{
                        that.download();
                    }, 2000)
                } else{
                    Toast({ message: res.msg, position:'cente'});
                }
                this.loading = false;
            }).catch(err=>{
                console.log(err)
                this.loading = false;
                Toast({ message: '网络繁忙，请稍后重试', position:'cente'});
            })
        },
        download(){
            this.$router.push({
                path: '/download'
            })
        }
    }
}
</script>
<style scoped>
.logo{
    text-align: center;
    padding: 15px;
}
.logo img{
    width: 100px;
    height: 100px;
}
.form-item{
  margin: 20px 30px;
}
.form-item .name{
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(113, 113, 122, 1);
  text-align: left;
  vertical-align: top;
  margin-bottom: 8px;
}
.form-item input{
  background: rgba(173, 173, 173, 0.1);
  height: 48px;
  line-height: 48px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(63, 63, 70, 1);
  text-align: left;
  vertical-align: top;
  padding: 0 16px;
}
.button-submit{
  height: 44px;
  background:  rgba(32, 197, 105, 1);
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 0px;
  line-height: 44px !important;
  text-align: center;
  color: #ffffff;
  border-radius: 10px !important;
  margin: 30px 30px;
  padding: 0 !important;
}
.button-download{
   text-align: center;
   color: rgba(32, 197, 105, 1);
   font-size: 15px;
   padding: 5px;
}

.copyright{
    padding: 20px 0 10px 0;
    text-align: center;
    color: #999999;
    font-size: 13px;
}

.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
    background: rgba(0, 0, 0, 0.6);
}
.popup .content{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 15px;
    text-align: center;
    padding: 30px;
}
.popup .content .icon-success{
    width: 80px;
    height: 80px;
}
.popup .content .success{
    color: #20c569;
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
}
.popup .content .tips{
    margin-top: 20px;
    font-size: 18px;
    color: #666666;
    text-align: center;
}
.popup .content .download{
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    border: #f0f0f0 solid 1px;
    border-radius: 10px;
    color: #333333;
}

.radio-agreement{
  display: flex;
  align-items: center;
  margin: 10px 30px;
}
.radio-agreement .agreement{
   font-size: 13px;
   color: #666666;
}
.radio-agreement .agreement .href{
   color: rgba(32, 197, 105, 1);
}
input[type="checkbox"]{
  width: 16px; 
  height: 16px;
  border-radius: 100%;
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  text-align: center;
  margin: 5px;
}
input[type="checkbox"]:checked{
    color: #fff; /* 这里也可以设置对钩的颜色 */
    background-color: rgba(32, 197, 105, 1);
    border-color: rgba(32, 197, 105, 1);
}

</style>